<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      HrDepartmentKpis: [],
      HrEmployees: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrDepartmentKpis: null,
      HrDepartmentKpisSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addHrEmployeeKpiRecords(app) {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "hr_department_kpi_id",
            type: "select",
            options: (() => {
              return app?.hr_department?.hr_department_kpis.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Department Kpi",
          },
          { model: "value", type: "number", label: "value" },
          { model: "month", type: "month", label: "month" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app?.hr_employee?.id;
              obj.year = obj.month.split("-")[0];
              obj.month = obj.month.split("-")[1];
              this.http.post("hr-employee-kpi-records", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrDepartmentKpis(app) {
      var data = {
        title: "popups.hr_department_kpis",
        inputs: [
          {
            model: "hr_department_id",
            type: "text",
            label: "hr_department_id",
            value: app.hr_department_id,
          },
          { model: "name", type: "text", label: "name", value: app.name },
          { model: "weight", type: "text", label: "weight", value: app.weight },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-department-kpis", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrDepartmentKpisSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-department-kpis/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrDepartmentKpis = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrDepartmentKpisSearchMode = false;
      this.get(this.page);
    },
    getHrDepartmentKpis() {
      this.http.get("hr-department-kpis").then((res) => {
        this.HrDepartmentKpis = res.data;
      });
    },
    deleteHrDepartmentKpis(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("hr-department-kpis", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      this.http
        .post("hr-employees/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployees = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>

    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.performance_management')"
    />
    <div class="row mb-4">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="HrDepartmentKpisSearchMode"
          @click="cancelappsearchMode()"
        ></button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("hr_employees.id") }}</th>
          <th scope="col">{{ $t("hr_employees.name") }}</th>
          <th scope="col">{{ $t("hr_employees.birthdate") }}</th>
          <th scope="col">{{ $t("hr_employees.basic_salary") }}</th>
          <th scope="col">{{ $t("hr_employees.job_title") }}</th>
          <th scope="col">{{ $t("hr_employees.department") }}</th>
          <th scope="col">{{ $t("hr_employees.created") }}</th>
          <th scope="col">{{ $t("hr_employees.updated") }}</th>
          <th scope="col">{{ $t("hr_employees.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in HrEmployees" :key="app" class="text-center">
          <td>{{ app?.hr_employee?.id }}</td>
          <td>{{ app?.hr_employee?.name }}</td>
          <td>{{ app?.hr_employee?.birthdate }}</td>
          <td>
            {{
              app?.hr_employee?.basic_salary
                ? app?.hr_employee?.basic_salary
                : "-"
            }}
          </td>
          <td>{{ app?.hr_job_title?.name }}</td>
          <td>{{ app?.hr_department?.name }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td class="d-flex justify-content-around">
            <a
              class="btn btn-primary"
              href="javascript: void(0);"
              role="button"
              @click="addHrEmployeeKpiRecords(app)"
            >
              add kpi
            </a>
            <router-link
              :to="{
                name: 'performance_management_profile',
                params: {
                  name: app?.name,
                  id: app?.hr_employee?.id,
                },
              }"
            >
              <button
                class="btn btn-info mx-1"
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                :title="
                  app?.hr_employee?.name + ' ' + $t('hr_employees.profile')
                "
              >
                <i class="bx bxs-detail"></i>
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages >= 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->

</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
